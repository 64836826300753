import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import  Register  from './Register'
import  Login  from './Login'
import  List  from './List'
import  Sendmail  from './Sendmail'
import  SpamCheck  from './SpamCheck'
import  ResultPage  from './ResultPage'
import  Dashboard  from './Dashboard'
import ResponsiveGrid from './ResponsiveGrid';

import  Bookings  from './Bookings'


//import { styled, alpha } from '@mui/material/styles';


function App() {



  return (
   <>
   <BrowserRouter>
      <Routes>
         <Route path ='/' element={<Dashboard />} />
         <Route path ='/register' element={<Register />} />
         <Route path ='/login' element={<Login />} />
         <Route path ='/list' element={<List />} />
         <Route path ='/Sendmail' element={<Sendmail />} />
         <Route path ='/SpamCheck' element={<SpamCheck />} />
         <Route path ='/ResultPage' element={<ResultPage />} />
         <Route path ='/Dashboard' element={<Dashboard />} />
         <Route path ='/Bookings' element={<Bookings />} />
         <Route path ='/ResponsiveGrid' element ={<ResponsiveGrid />} />


   </Routes>
   </BrowserRouter>
   </>
  );
}

export default App;
