import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import SendIcon from '@mui/icons-material/Send';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useNavigate}  from "react-router-dom";


//import { styled, alpha } from '@mui/material/styles';


function Register() {
const navigate = useNavigate()

  // State to store the form data
 const [userName, setName] = useState()
  const [userPassword, setPassword] = useState()
    const [userEmail, setEmail] = useState()




  function onLoginClick(e) {
        console.log("onLoginClick");
        //navigate("")
        navigate('/login', { replace: true })
        
    }

  


  // Function to submit the form data using Axios
  const onRegisterClick = async (e) => {
    console.log("register................................")
    e.preventDefault();
    try {
      const response = await      axios.post("http://localhost:5000/user/register", { userName, userPassword , userEmail})
  navigate('/login', { replace: true });
      console.log("Post created:", response.data);
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };



  return (
    <div
style={{
  display: "table",
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0
}}
>
<div
style={{
  display: "table-cell",
  verticalAlign: "middle",
  textAlign: "center"
}}
>

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '50ch' },
      }}
      autoComplete="off"
    >
      <form action="" id="login" method="post">


         <TextField
          id="userName" 
          label="USER NAME"
                      onChange={e => setName(e.target.value)}

        /><br/>
 <TextField
          id="email" 
          label="userEmail"
                      onChange={e => setEmail(e.target.value)}

        />
<br/>
         <TextField
          id="userPassword" 
          label="PASSWORD"
                      onChange={e => setPassword(e.target.value)}

        />
<br/>
        <Button variant="contained"  type="submit" onClick={onRegisterClick}>REGISTER</Button><br/>
       <Button variant="contained" style={{
    marginTop: '5px'  }}   onClick={onLoginClick}>LOGIN</Button>


</form>
        </Box>
</div>
   </div>
  );
}

export default Register;
