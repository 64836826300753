import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from "react";
import axios from "axios";

function Bookings(){
	  const [data, setData] = useState([]);

 // Call fetchData on component mount
    useEffect(() => {
    fetchData();
  }, []);


  // Function to fetch data using Axios
  const fetchData = async () => {
    try {
      const response = await axios.post("http://165.232.178.238:8056/list", {  "username":"asdsdad" })
console.log(response.data);
      setData(response.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

	  return (
   <div
style={{
  display: "table",
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0
}}
>
<div
style={{
  display: "table-cell",
  verticalAlign: "middle",
  textAlign: "center"
}}
>

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >


	 {data.map((row) => (
<Card   key={row.bookingID} sx={{ maxWidth: 345 }}>
      <CardMedia
      key={row.bookingID}
      style={{backgroundColor: "#ADD8E6"}}


     
        sx={{ height: 50 }}
        title= {row.vehicleNumber}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {row.userName}
              {row.mobileNumber}
                  {row.vehicleNumber}
        </Typography>
        <Typography variant="body2" color="text.secondary">
       {row.VehicleNumber}
        </Typography>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
     
     ))}
  </Box>

</div>
   </div>
  );
}

export default Bookings;
