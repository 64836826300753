import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import SendIcon from '@mui/icons-material/Send';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {useNavigate}  from "react-router-dom";

//import { styled, alpha } from '@mui/material/styles';


function Sendmail() {
const navigate = useNavigate()



  const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

   const [message, setMessage] = useState()
    const [subject, setSubject] = useState()

    console.log(localStorage.getItem('senderUserEmail'))

    const [senderUserEmail, setSenderUserEmail] = useState(localStorage.getItem('senderUserEmail'))
    const [senderUserName, setSenderUserName] = useState(localStorage.getItem('senderUserName'))


    const [receiverUserEmail, setReceiverUserEmail] = useState()
    const [receiverUserName, setReceiverUserName] = useState()


    const [fileUrl, setFileUrl] = useState()


  const onEmailSend = async (event) => {
    event.preventDefault()
    const url = 'http://localhost:5000/success';
    const formData = new FormData();
    console.log(file);
    formData.append('file', file);
   //formData.append('fileName', file.name);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
    axios.post(url, formData).then((response) => {
      //console.log(response.data.fileUrl);
      //setFileUrl(response.data.fileUrl)
      var fileData = response.data.fileUrl


     try {

      console.log("fileUrl===========")
      console.log(fileData)
      const response =       axios.post("http://localhost:5000/send/mail", {  message , subject, senderUserEmail
     ,senderUserName, receiverUserEmail , receiverUserName, fileData})
 // navigate('/login', { replace: true });
      console.log("Login success:", response.data);
alert("Login Success");
      navigate('/Sendmail', { replace: true });
    } catch (error) {
      console.error("Error creating post:", error);
    }
    });

  }





 function onInboxClick(e) {
        console.log("onInboxClick");
        //navigate("")
        navigate('/List', { replace: true })
        
    }

    const onEmailSendsssss = async (e) => {
    console.log("register................................")
    e.preventDefault();
    try {

    

      const response = await      axios.post("http://localhost:5000/send/mail", {  message , subject, senderUserEmail
     ,senderUserName, receiverUserEmail , receiverUserName, fileUrl})
 // navigate('/login', { replace: true });
      console.log("Login success:", response.data);
alert("Login Success");
      navigate('/Sendmail', { replace: true });
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };


 const [file, setFile] = useState();
 const [filer, setMyFile] = useState();

 

    function handleChange(e) {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setMyFile(URL.createObjectURL(e.target.files[0]));
    }

  return (
   <div>
     <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MyMail
          </Typography>
          <Button color="inherit"  onClick={onInboxClick}>Inbox</Button>
        </Toolbar>
      </AppBar>
  <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100ch' },
      }}
      noValidate
      autoComplete="off"
    >

<TextField id="outlined-basic"
onChange={e => setReceiverUserEmail(e.target.value)}
 label="To"
  variant="outlined" />

<TextField id="outlined-basic"
onChange={e => setSubject(e.target.value)}
 label="Subject"
  variant="outlined" />

    <TextField
          id="outlined-multiline-static"
          label="Body"
          multiline
          rows={4}
          onChange={e => setMessage(e.target.value)}

        />

            <h2>Add Image:</h2>

    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      type="file"
      onChange={handleChange} 
      startIcon={<CloudUploadIcon />}
    >
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
&nbsp;&nbsp;&nbsp;


             <Button variant="contained" onClick={onEmailSend} endIcon={<SendIcon />}>
        Send
      </Button>
                  <img src={filer} width="200" height="200"/>

            </Box>
   
   </div>
  );
}

export default Sendmail;
