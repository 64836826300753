import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import BuildIcon from '@mui/icons-material/Build';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


const ResponsiveCard = ({ vehicleNumber, userName, mobileNumber,serviceType , bookingStatus,  bookingTime ,bookingDate, bookingBill}) => {
 
const StyledCard = styled(Card)({
  maxWidth: 345,
  margin: '20px auto',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  overflow: 'hidden',
  fontFamily: "'Roboto', sans-serif"
});


const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '16px',
  '& .MuiTypography-root': {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2em'
  }
});


const BillTypography = styled(Typography)({
  marginTop: '16px',
  backgroundColor: 'green',
  color: 'white',
  padding: '10px 16px',  // Increased left and right padding
  width: 'calc(100% + 32px)',  // Adjusted width to cover full card width
  marginLeft: '-16px',  // Negative margin to align with card edges
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '1.2em'
});



  return (

    <Grid item xs={12} sm={6} md={4} lg={3}>
    
   
    <StyledCard>
        <CardMedia
          alt={vehicleNumber}
        />
      <StyledCardContent>


          <Typography  variant="h5" color="text.secondary">
                    <DirectionsCarIcon style={{ marginRight: '8px' }} />

             {vehicleNumber}
          </Typography>

          <Typography variant="h5" color="text.secondary">
                    <PersonIcon style={{ marginRight: '8px' }} />

           {userName}
          </Typography> 

             <Typography variant="h5" color="text.secondary">
           <PhoneIcon style={{ marginRight: '8px' }} />
           {mobileNumber}
          </Typography> 

             <Typography variant="h5" color="text.secondary">
                   <BuildIcon style={{ marginRight: '8px' }} />

           {serviceType}
          </Typography> 

             <Typography variant="h5" color="text.secondary">
                   <AssignmentIcon style={{ marginRight: '8px' }} />

            {bookingStatus}
          </Typography> 

            <Typography variant="h5" color="text.secondary">
                 <CalendarTodayIcon style={{ marginRight: '8px' }} />

            {bookingDate}
          </Typography> 

    
       <Typography variant="h5" color="text.secondary">
                <AccessTimeIcon style={{ marginRight: '8px' }} />

           {bookingTime}
          </Typography> 
    
       <BillTypography variant="h5" color="text.secondary">
            {bookingBill}
            <CurrencyRupeeIcon style={{ marginRight: '8px' }} />

          
         </BillTypography>



      </StyledCardContent>
    </StyledCard>
    </Grid>
  );
};

export default ResponsiveCard;
