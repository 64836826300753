import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';


import SendIcon from '@mui/icons-material/Send';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {useNavigate}  from "react-router-dom";
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

//import { styled, alpha } from '@mui/material/styles';


function SpamCheck() {

  const [mailUUID, setMailUUID] = useState(localStorage.getItem('mailUUID'));


  const [qrCodeUrlSpamStatus, setQrCodeUrlSpamStatus] = useState();
  const [spamMessageStatus, setSpamMessageStatus] = useState();
  const [spamUrlStatus, setSpamUrlStatus] = useState();


   useEffect(() => {
    fetchData();
  }, []);


 // Function to fetch data using Axios
  const fetchData = async () => {
   try {
     const response =  await axios.post("http://localhost:5000/spam/details", { "mailUUID": mailUUID })
     console.log(response.data)
     if(response.data.data.qrCodeUrlSpamStatus == "spam"){
      setQrCodeUrlSpamStatus("SPAM QRCODE IMAGE URL DETECTED")
 
     }
     else{
      setQrCodeUrlSpamStatus("NO SPAM QRCODE IMAGE URL DETECTED")

     }

         if(response.data.data.spamMessageStatus == "spam"){
            
                setSpamMessageStatus("SPAM MESSAGE DETECTED")


     }
     else{
setSpamMessageStatus("NO SPAM MESSAGE DETECTED")
     }                



         if(response.data.data.spamUrlStatus == "spam"){
           setSpamUrlStatus("SPAM URL DETECTED")

     }
     else{
           setSpamUrlStatus("NO SPAM URL DETECTED")

     }
    // setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


function handleClick() {
    console.log("Button clicked");
  }
  return (
  <div>
   <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="info">
        <AlertTitle>Message Status</AlertTitle>
      { spamMessageStatus}
      </Alert>
     
      <Alert severity="info">
        <AlertTitle>Url Status</AlertTitle>
      { spamUrlStatus}
      </Alert>

      <Alert severity="info">
        <AlertTitle>QrCode status</AlertTitle>
       {qrCodeUrlSpamStatus}
      </Alert>
    </Stack>
   </div>
  );
}

export default SpamCheck;
