import { Grid, Container,Typography } from '@mui/material';
import ResponsiveCard from './ResponsiveCard';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';


const cardsData = [
  { title: 'Card 1', description: 'Description 1', imageUrl: 'https://via.placeholder.com/140' },
  { title: 'Card 2', description: 'Description 2', imageUrl: 'https://via.placeholder.com/140' },
  { title: 'Card 3', description: 'Description 3', imageUrl: 'https://via.placeholder.com/140' },
  { title: 'Card 4', description: 'Description 4', imageUrl: 'https://via.placeholder.com/140' },
];

const ResponsiveGrid = () => {


  const [data, setData] = useState([]);
  const [billCollection, setCollection] = useState([]);

 // Call fetchData on component mount
    useEffect(() => {
    fetchData();
  }, []);


  // Function to fetch data using Axios
  const fetchData = async () => {
    try {

     // const response = await axios.post("http://165.232.178.238:8056/list", {  "username":"asdsdad" })
      const response = await axios.post("http://165.232.178.238:8056/getBookingsbyDate", { "bookingDate":  localStorage.getItem('selectedDate') })

    //  const response = await axios.post("http://192.168.0.115:8056/getBookingsbyDate", { "bookingDate":  localStorage.getItem('selectedDate') })

console.log(response.data.bookingList);
      setCollection(response.data.billbyDates);


      setData(response.data.bookingList);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

const GradientBox = styled(Typography)({
  padding: '10px 16px',
  borderRadius: '8px',
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: 'white',
  marginTop: '16px',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.2em',
  width: '100%',
  boxSizing: 'border-box'
});



  console.log("Rendering ResponsiveGrid");
  return (
    <Container>
    <GradientBox variant="h5">
            Total Collection: {billCollection}
          </GradientBox>

      <Grid container spacing={4}>
        {data.map((card, index) => (
          <ResponsiveCard
            key={card.bookingID}
            vehicleNumber={card.vehicleNumber}
            userName={card.userName}
            mobileNumber = {card.mobileNumber}

                serviceType={card.serviceType}
            bookingStatus={card.bookingStatus}
            bookingTime = {card.bookingTime}
            bookingDate = {card.bookingDate}
            bookingBill = {card.bookingBill}

          />
        ))}
      </Grid>
    </Container>
  );
};

export default ResponsiveGrid;
