import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import SendIcon from '@mui/icons-material/Send';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {useNavigate}  from "react-router-dom";
//import { styled, alpha } from '@mui/material/styles';


function ResultPage() {
const navigate = useNavigate()

  const [userPassword, setPassword] = useState()
    const [userEmail, setEmail] = useState()

 //const history = useHistory();

  // Function to submit the form data using Axios
  const onLoginClick = async (e) => {
    console.log("register................................")
    e.preventDefault();
    try {
      const response = await      axios.post("http://localhost:5000/user/login", {  userPassword , userEmail})
 // navigate('/login', { replace: true });
      console.log("Login success:", response.data);
alert("Login Success");
      navigate('/Sendmail', { replace: true });
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };
      function onRegisterClick(e) {
        console.log("onRegisterClick");
        //navigate("")
        navigate('/register', { replace: true })
        
    }



function handleClick() {
    console.log("Button clicked");
  }
  return (
   <div
style={{
  display: "table",
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0
}}
>
<div
style={{
  display: "table-cell",
  verticalAlign: "middle",
  textAlign: "center"
}}
>

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >
 <TextField
          required
          id="outlined-required"
          label="Email"
           onChange={e => setEmail(e.target.value)}
        />
 <br/>
         <TextField
          required
          id="outlined-required"
          label="Password"
            onChange={e => setPassword(e.target.value)}
        /> <br/>



<div>
        <Button variant="contained"    size="medium"
  onClick={onLoginClick} >LOGIN</Button> <br/> </div>
        <Button  style={{
    marginTop: '5px'  }} variant="contained" onClick={onRegisterClick}>REGISTER</Button>

        </Box>

</div>
   </div>
  );
}

export default ResultPage;
