import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";

import { Avatar, Box, Stack } from '@mui/material';
import CarWashIcon from '@mui/icons-material/LocalCarWash'; // Example icon


import { Card, CardContent, CardMedia, Grid } from '@mui/material';


import SendIcon from '@mui/icons-material/Send';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {  Container } from '@mui/material';
import ResponsiveCard from './ResponsiveCard';
import {useNavigate}  from "react-router-dom";
//import { styled, alpha } from '@mui/material/styles';
import { Button } from '@mui/material';
import { CssBaseline, Typography } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function Dashboard() {
const navigate = useNavigate()
  //const [value, setValue] = React.useState(dayjs('2022-04-17'));

  const [date, setDate] = useState(new Date());

 const handleClick = () => {
    //alert('Button clicked!');
            navigate('/ResponsiveGrid', { replace: true })

  };




  const handleDateChange = (newDate) => {
    setDate(newDate);

     const day = String(newDate.getDate()).padStart(2, '0');
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const year = newDate.getFullYear();
    localStorage.setItem('selectedDate',`${day}-${month}-${year}`);

console.log(     );

    // console.log(newDate.toLocaleDateString('en-GB', {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    // }));
  //  console.log('Selected date:', newDate); // Perform any additional actions here
  navigate('/ResponsiveGrid', { replace: true });
  };  
  return (
     <div className="background">
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Stack spacing={2} alignItems="center">
          <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>
            <CarWashIcon />
          </Avatar>
          <Calendar onChange={handleDateChange} value={date} />
        </Stack>
      </Box>
    </div>
  );
}

export default Dashboard;
