import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {useNavigate}  from "react-router-dom";

function List() {
const navigate = useNavigate()

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


  const [data, setData] = useState([]);
  const [receiverUserEmail, setReceiverUserEmail] = useState(localStorage.getItem('senderUserEmail'));


  // Call fetchData on component mount
    useEffect(() => {
    fetchData();
  }, []);


  const onCheckSpam = async (e) => {

    console.log("onCheckSpam................................")
    console.log(e.target.value)
    e.preventDefault();
          //navigate('/SpamCheck', { replace: true });

    
  };
      

  // Function to fetch data using Axios
  const fetchData = async () => {
    try {
      const response = await axios.post("http://localhost:5000/mail/list", {  receiverUserEmail })
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick =  (index) => async(event) => {
        console.log("onCheckSpam................................")
console.log(index)
localStorage.setItem('mailUUID',index);
navigate('/SpamCheck', { replace: true });

  // try {
  //    const response =  await axios.post("http://localhost:5000/spam/details", { "mailUUID": index })
  //    console.log(response.data)
  //   // setData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  }

  return (
   <div>


 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Sender name</StyledTableCell>
                        <StyledTableCell align="right">Sender mail</StyledTableCell>

            <StyledTableCell align="right">Subject</StyledTableCell>
            <StyledTableCell align="right">Body</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.mailUUID} onClick={handleClick (row.mailUUID)}>
              <StyledTableCell component="th" scope="row">  {row.senderUserName}    </StyledTableCell>
                            <StyledTableCell align="right">{row.senderUserEmail}</StyledTableCell>

              <StyledTableCell align="right">{row.subject}</StyledTableCell>
                            <StyledTableCell align="right">{row.message}</StyledTableCell>

              <StyledTableCell align="right">        <Button variant="contained"   >Check</Button>
</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

   </div>
  );
}

export default List;
